import React from "react";
import PropTypes from "prop-types";

// MUI
import { MainContainer } from "./styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Icons
import { check_circle_icon, times_circle_icon } from "../../@global/icons";

const SubmitFeedback = (props) => {
  const { message, primaryCb, btn_text = "Back", success = true } = props;

  return (
    <MainContainer>
      <SvgIcon
        fontSize="medium"
        viewBox="0 0 32 32"
        className={`${success ? "success-color" : "error-color"} check-icon`}
      >
        {success ? check_circle_icon : times_circle_icon}
      </SvgIcon>
      <Typography
        component="div"
        className={`${success ? "success-color" : "error-color"} message`}
      >
        {message}
      </Typography>
      {primaryCb && (
        <Button
          variant="outlined"
          size="small"
          onMouseDown={(e) => primaryCb()}
        >
          {btn_text}
        </Button>
      )}
    </MainContainer>
  );
};

SubmitFeedback.propTypes = {
  message: PropTypes.node.isRequired,
  btn_text: PropTypes.string,
  primaryCb: PropTypes.func,
  success: PropTypes.bool,
};

export default SubmitFeedback;
