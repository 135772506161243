import styled from "styled-components";

import Container from "@material-ui/core/Container";

import { header_height, sidebar_width } from "../@global/const";

const hor_padding = 13;

export const MainContainer = styled(Container)`
  position: fixed;
  z-index: ${(p) => p.theme.zIndex.header};
  height: ${header_height}px;

  display: flex;
  justify-content: space-between;

  background-color: ${(p) => p.theme.palette.background.paper};
  border-bottom: 1px solid ${(p) => p.theme.palette.divider};

  padding-left: ${hor_padding}px;
  padding-right: ${hor_padding}px;

  .logo,
  .auth {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logo img {
    opacity: 1;

    transition: opacity ${(p) => p.theme.transitions.duration.standard}ms
      ${(p) => p.theme.transitions.easing.easeIn};
  }

  .logo.narrow img {
    opacity: 0;

    transition: opacity ${(p) => p.theme.transitions.duration.shortest}ms
      ${(p) => p.theme.transitions.easing.easeOut};
  }

  .menu-btn {
    position: absolute;
    z-index: 1;

    min-width: 46px;

    left: ${sidebar_width}px;
    top: 50%;
    transform: translateY(-50%) translateX(-100%);

    transition: all ${(p) => p.theme.transitions.duration.standard}ms
      ${(p) => p.theme.transitions.easing.easeOut};

    &.narrow {
      left: ${hor_padding}px;
      transform: translateY(-50%) translateX(0%);
    }
  }

  .avatar {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border: 2px solid #fafafa;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(48, 49, 51, 0.08),
      0 4px 20px 0 rgba(48, 49, 51, 0.17);
  }
`;
