import React from "react";

// Apollo GraphGL
import {
  from,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

// MUI
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

import GlobalStyles from "../@global/styles";
import { default_theme_obj } from "../@global/const";
import { MainContainer } from "./styles";

// Router
import { Router } from "@reach/router";

// Utils
import { version } from "../../package.json";
import { getCacheTypePolicies } from "../@utils/apollo";

// Data
import { pages_data, sidebar_collapsed_width } from "../@global/const";

// Services
import logger from "../@services/logger";
import keycloak, { initialize } from "../@services/keycloak";

// Context
import { LayoutContext } from "../@context/LayoutContext";

// Components
import Header from "../Header";
import Sidebar from "../Sidebar";
import NotFound from "../@pages/NotFound";
import Page from "../Page";

/*
 *   ******************
 *   App Initialization
 *   ******************
 */

// Apollo Setup
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      Authorization: keycloak.authenticated ? `Bearer ${keycloak.token}` : "",
      Version: version,
    },
  }));
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      logger.debug(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  // if (networkError)
  //   alert(`[Network error]:
  // GraphQL URL: ${window._env_.REACT_APP_GRAPHQL}
  // ERROR: s${networkError}`);
});

const link = from([
  authLink,
  errorLink,
  new HttpLink({
    uri: window._env_.REACT_APP_GRAPHQL,
    credentials: "same-origin",
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(getCacheTypePolicies()),
  link,
});

// Theming
const theme = responsiveFontSizes(createTheme(default_theme_obj));

/*
 *   ******************
 *   Root App Component
 *   ******************
 */

function App() {
  // Hooks
  const [auth_init, setAuthInit] = React.useState(false);
  const { is_small, is_tiny } = React.useContext(LayoutContext);

  React.useEffect(() => {
    if (!auth_init) {
      initialize(60000)
        .then((res) => {
          setAuthInit(true);
        })
        .catch((err) => logger.debug(err));
    }
  }, [auth_init, setAuthInit]);

  // Render
  return (
    <ApolloProvider client={client}>
      <StylesProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <GlobalStyles theme={theme} />

          <MainContainer
            maxWidth={false}
            disableGutters
            theme={theme}
            collapsed_width={is_tiny ? 0 : sidebar_collapsed_width}
          >
            <Header parsed_id_token={keycloak.idTokenParsed} />
            <main>
              <Sidebar />
              {auth_init && (
                <Router
                  className={
                    is_small ? "pages-container small" : "pages-container"
                  }
                >
                  {pages_data.map((category, cat_index) =>
                    category.pages.map((page_data, page_index) => (
                      <Page
                        key={String(cat_index).concat(page_index)}
                        path={page_data.uri}
                        header={page_data.header}
                        title={page_data.title}
                        category={page_data.category}
                        component={page_data.component}
                      />
                    ))
                  )}
                  <NotFound default />
                </Router>
              )}
            </main>
          </MainContainer>
        </ThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  );
}

export default App;
