import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const LayoutContext = React.createContext();

const LayoutProvider = (props) => {
  const { children } = props;

  // Hooks
  const theme = useTheme();

  const is_initial_small = window.innerWidth < theme.breakpoints.values.md;
  const is_initial_tiny = window.innerWidth < theme.breakpoints.values.sm;

  const [collapse_menu, setCollapseMenu] = React.useState(is_initial_small);
  const is_small = useMediaQuery(theme.breakpoints.down("sm")) || is_initial_small;
  const is_tiny = useMediaQuery(theme.breakpoints.down("xs")) || is_initial_tiny;

  return (
    <LayoutContext.Provider value={{ is_small, is_tiny, collapse_menu, setCollapseMenu }}>
      {children}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
