import React from "react";

// MUI
import { MainContainer } from "./styles";
import Typography from "@material-ui/core/Typography";

import { useTheme } from "@material-ui/core/styles";

const NotFound = (props) => {
  // Hooks
  const theme = useTheme();

  return (
    <MainContainer theme={theme}>
      <Typography variant="h1">404</Typography>
      <Typography variant="body1">{`Oops something went wrong :(`}</Typography>
      <Typography variant="body1">
        The page you requested doesn't seem to exist
      </Typography>
    </MainContainer>
  );
};

export default NotFound;
