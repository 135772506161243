export const getCacheTypePolicies = () => {
  return {
    typePolicies: {
      UserType: {
        fields: {
          avatar: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  };
};
