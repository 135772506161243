import React from "react";

// Apollo
import { useLazyQuery } from "@apollo/client";
import { USER } from "../@global/queries";

// Context
import { LayoutContext } from "../@context/LayoutContext";

// MUI
import { MainContainer } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import Skeleton from "@material-ui/lab/Skeleton";

// Icons
import { bars_icon } from "../@global/icons";

// Assets
import logo_img from "../@images/aria.png";

// Services
import keycloak from "../@services/keycloak";

// Components
import LoadableImage from "../@common/LoadableImage";

const Header = (props) => {
  const { parsed_id_token } = props;

  // Hooks
  const theme = useTheme();
  const { collapse_menu, setCollapseMenu } = React.useContext(LayoutContext);

  const [loadUser, { data, called }] = useLazyQuery(USER);

  React.useEffect(() => {
    if (parsed_id_token && !called) {
      loadUser({ variables: { username: parsed_id_token.sub } });
    }
  }, [parsed_id_token, called, loadUser]);

  const avatar = (((data || {}).userItems || [])[0] || {}).avatar || {};
  const avatar_url =
    avatar.url || (avatar.data && avatar.type && `data:${avatar.type};base64,${avatar.data}`);

  return (
    <MainContainer theme={theme} maxWidth={false} disableGutters component="header">
      <Button
        className={collapse_menu ? "menu-btn narrow" : "menu-btn"}
        onMouseDown={(e) => setCollapseMenu(!collapse_menu)}
      >
        <SvgIcon viewBox="0 0 32 32">{bars_icon}</SvgIcon>
      </Button>
      <figure className={collapse_menu ? "logo narrow" : "logo"}>
        <img src={logo_img} alt="ARIA Logo" height="32" width="32" />
      </figure>
      <div className="auth">
        <Button onMouseDown={(e) => keycloak.logout()}>Log out</Button>

        {avatar_url && (
          <React.Suspense
            fallback={<Skeleton className="avatar" variant="circle" width={32} height={32} />}
          >
            <LoadableImage url={avatar_url} alt="avatar" className="avatar" />
          </React.Suspense>
        )}
      </div>
    </MainContainer>
  );
};

export default Header;
