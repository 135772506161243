import React from "react";
import PropTypes from "prop-types";

// Auth
import keycloak from "../@services/keycloak";

// Apollo
import { useQuery } from "@apollo/client";
import { USER } from "../@global/queries";

// Utils
import logger from "../@services/logger";

// Components
import Loader from "./Loader";
import SubmitFeedback from "../@common/SubmitFeedback";
import ErrorBoundary from "../@common/ErrorBoundary";

const Page = (props) => {
  const { title, header, component } = props;

  document.querySelector("title").innerText = title;

  // Hooks
  const PageComponent = React.useRef();

  const { data, error } = useQuery(USER, {
    variables: { username: keycloak.idTokenParsed.sub },
  });

  PageComponent.current =
    PageComponent.current ||
    React.lazy(() =>
      import(`../@pages/${component}`).catch((error) => {
        logger.error(error);

        return import("./NullPage");
      })
    );

  const user = ((data || {}).userItems || [])[0];

  let error_report = "";

  if (error) {
    const networkError = error.networkError || {};
    const url = (networkError.response || {}).url;
    const errors = (networkError.result || {}).errors || [];
    const error_messages = errors.map((err) => err.message).join("; ");

    error_report = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h3>Unfortunately User data couldn't be loaded!</h3>
        <br />
        <span>The following error occured:</span>
        <span>{error.message}</span>
        <br />
        <span>Service URL: {url}</span>
        <br />
        <span>Messages: {error_messages}</span>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      {error ? (
        <SubmitFeedback message={error_report} success={false} />
      ) : (
        <React.Suspense fallback={<Loader />}>
          <PageComponent.current header={header} user={user} />
        </React.Suspense>
      )}
    </ErrorBoundary>
  );
};

Page.propTypes = {
  path: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
};

export default Page;
