import React from "react";

import Typography from "@material-ui/core/Typography";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "50vh",
      }}
    >
      <Typography color="textSecondary">loading...</Typography>
    </div>
  );
};

export default Loader;
