import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 400px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
`;
