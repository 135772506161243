// Keycloak
import Keycloak from "keycloak-js";

// Services
import logger from "./logger";

// Axios
import axios from "axios";

window._env_ = window._env_ || {};

const keycloak = new Keycloak({
  realm: window._env_.REACT_APP_KEYCLOAK_REALM,
  clientId: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID,
  url: `${window._env_.REACT_APP_KEYCLOAK_BASE_URL}/auth/`,
});

export const initialize = (refresh_time = 5000) => {
  return new Promise((resolve, reject) => {
    keycloak
      .init({ onLoad: "login-required", checkLoginIframe: false })
      .then((authenticated) => {
        logger.debug("Keycloak authenticated: " + authenticated);

        setInterval(checkToken, refresh_time);

        checkToken();

        resolve(authenticated);
      })
      .catch(function () {
        const err = "Failed to initialize Keycloak";

        logger.error(err);

        reject(new Error(err));
      });
  });
};

export const checkToken = () => {
  return new Promise((resolve, reject) => {
    keycloak
      .updateToken(60)
      .then(function (refreshed) {
        if (refreshed) {
          logger.debug("Token was successfully refreshed");
        } else {
          logger.debug("Token is still valid");
        }

        axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;

        resolve();
      })
      .catch(function () {
        const err = "Failed to refresh the token, or the session has expired";

        logger.error(err);

        keycloak.clearToken();

        reject(new Error(err));
      });
  });
};

export default keycloak;
