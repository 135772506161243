import { user_icon, lock_icon } from "../@global/icons";

export const default_theme_obj = {
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      main: "#6488FF",
    },
    secondary: {
      main: "#17ADDE",
    },
    success: {
      main: "#99C24D",
    },
    warning: {
      main: "#F18F01",
    },
    error: {
      main: "#E93141",
    },
    text: {
      primary: "#565B70",
      secondary: "#76797B",
    },
    grey: {
      300: "#BFC3C7",
      400: "#959BA2",
      500: "#959BA2",
      600: "#6A727C",
      700: "#2A3644",
      800: "#283340",
    },
    divider: "#eaebec",
  },
  zIndex: {
    sidebar: 100,
    header: 200,
  },
};

export const header_height = 56;
export const sidebar_width = 240;
export const sidebar_collapsed_width = 60;

export const pages_data = [
  {
    name: "Account",
    icon: user_icon,
    pages: [
      {
        uri: "/",
        menu: "Account Details",
        title: "Account Details",
        header: "Account Details",
        component: "AccountDetails",
      },
      {
        uri: "/profile",
        menu: "Your Profile",
        title: "Your Profile",
        header: "Your Profile",
        component: "YourProfile",
      },
      {
        uri: "/orcid",
        menu: "ORCID connection",
        title: "ORCID connection",
        header: "ORCID connection",
        component: "ConnectOrcid",
      },
    ],
  },
  {
    name: "Security",
    icon: lock_icon,
    pages: [
      {
        uri: "/change-password",
        menu: "Change Password",
        title: "Change Password",
        header: "Change Password",
        component: "ChangePassword",
      },
      // {
      //   uri: "/some-page",
      //   menu: "2-factor Authentication",
      //   title: "2-factor Authentication",
      //   header: "2-factor Authentication",
      //   component: "",
      // },
    ],
  },
];
