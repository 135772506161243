import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .check-icon {
    font-size: 5.5rem;
    margin-bottom: 10px;
  }

  .message {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
`;
