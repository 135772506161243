import React from "react";

// Router
import { navigate } from "@reach/router";
import { Location } from "@reach/router";

// Context
import { LayoutContext } from "../@context/LayoutContext";

// MUI
import { MainContainer, Screen } from "./styles";

import { useTheme } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SvgIcon from "@material-ui/core/SvgIcon";
import Collapse from "@material-ui/core/Collapse";

// Icons
import { arrow_right_icon } from "../@global/icons";

// Data
import { pages_data } from "../@global/const";

// Utils
import { sidebar_collapsed_width } from "../@global/const";

const Sidebar = (props) => {
  // Hooks
  const theme = useTheme();
  const {
    is_small,
    is_tiny,
    collapse_menu,
    setCollapseMenu,
  } = React.useContext(LayoutContext);

  const [menu_state, setMenuState] = React.useState(
    pages_data.map(() => !collapse_menu)
  );

  React.useEffect(() => {
    if (collapse_menu && menu_state.find((category) => category === true)) {
      setMenuState([].fill(false, 0, menu_state.length - 1));
    }
  }, [collapse_menu, menu_state]);

  const checkSelected = (pathname, cat_index) => {
    return Boolean(
      pages_data[cat_index].pages.find((page) => page.uri === pathname)
    );
  };

  return (
    <>
      {is_small && !collapse_menu && (
        <Fade in={!collapse_menu} timeout={300}>
          <Screen theme={theme} onMouseDown={(e) => setCollapseMenu(true)} />
        </Fade>
      )}

      <MainContainer
        theme={theme}
        collapsed_width={is_tiny ? 0 : sidebar_collapsed_width}
        className={is_small ? "small" : ""}
      >
        <Location>
          {({ location }) => {
            return (
              <nav className={collapse_menu ? "collapsed" : ""}>
                <List component="ul" className="menu-root">
                  {pages_data.map((category, cat_index) => {
                    return (
                      <List component="li" key={cat_index}>
                        <ListItem
                          button
                          selected={checkSelected(location.pathname, cat_index)}
                          onClick={(e) => {
                            if (collapse_menu) setCollapseMenu(false);

                            const new_menu_state = [...menu_state];

                            new_menu_state[cat_index] = !new_menu_state[
                              cat_index
                            ];

                            setMenuState(new_menu_state);
                          }}
                        >
                          <ListItemIcon>
                            <div className="item-icon">
                              <SvgIcon fontSize="medium" viewBox="0 0 32 32">
                                {category.icon}
                              </SvgIcon>
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={category.name} />
                        </ListItem>
                        <Collapse
                          in={menu_state[cat_index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="ul" className="nested">
                            {category.pages.map((menu_item, page_index) => (
                              <ListItem
                                key={page_index}
                                button
                                className="sub-item"
                                selected={location.pathname === menu_item.uri}
                                component="li"
                                onMouseDown={(e) => navigate(menu_item.uri)}
                              >
                                <ListItemText primary={menu_item.menu} />
                                <SvgIcon fontSize="inherit">
                                  {arrow_right_icon}
                                </SvgIcon>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </List>
                    );
                  })}
                </List>
              </nav>
            );
          }}
        </Location>
      </MainContainer>
    </>
  );
};

export default Sidebar;
