import React from "react";
import PropTypes from "prop-types";

import { useImage } from "react-image";

import ErrorBoundary from "../ErrorBoundary";

const ImageLoader = (props) => {
  const { url, alt, className } = props;

  const { src } = useImage({
    srcList: url,
  });

  return <img src={src} alt={alt} className={className} />;
};

const LoadableImage = (props) => {
  const { url, alt, className } = props;

  return (
    <ErrorBoundary>
      <ImageLoader url={url} alt={alt} className={className} />
    </ErrorBoundary>
  );
};

LoadableImage.protTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LoadableImage;
