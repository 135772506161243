import styled from "styled-components";

import { sidebar_width, header_height } from "../@global/const";

export const MainContainer = styled.aside`
  background-color: ${(p) => p.theme.palette.background.paper};
  border-right: 1px solid ${(p) => p.theme.palette.divider};

  overflow: auto;
  height: calc(100vh - ${header_height}px);

  &.small {
    position: absolute;
    z-index: ${(p) => p.theme.zIndex.sidebar};

    top: ${header_height}px;
    bottom: 0;
  }

  nav {
    overflow: hidden;

    width: ${sidebar_width}px;

    transition: width ${(p) => p.theme.transitions.duration.standard}ms
      ${(p) => p.theme.transitions.easing.easeOut};

    &.collapsed {
      width: ${(p) => p.collapsed_width}px;
    }

    .menu-root {
      width: ${sidebar_width}px;
    }

    .nested {
      padding-left: 45px;
    }

    .MuiList-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiListItem-root {
      color: inherit;
      transition: color ${(p) => p.theme.transitions.duration.shortest}ms
        ${(p) => p.theme.transitions.easing.easeOut};

      .MuiListItemIcon-root {
        min-width: 45px;
      }

      .item-icon {
        background-color: ${(p) => p.theme.palette.text.primary};
        transition: background-color
          ${(p) => p.theme.transitions.duration.shortest}ms
          ${(p) => p.theme.transitions.easing.easeOut};

        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;

        svg {
          fill: white;
        }
      }

      &.sub-item span {
        font-size: 0.875rem;
      }

      &.Mui-selected,
      &:hover {
        background: none;

        color: ${(p) => p.theme.palette.primary.main};

        .item-icon {
          background-color: ${(p) => p.theme.palette.primary.main};
        }
      }
    }
  }
`;

export const Screen = styled.div`
  position: absolute;
  z-index: ${(p) => p.theme.zIndex.sidebar - 1};

  top: ${header_height}px;
  left: 0;
  bottom: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.5);
  opacity: 0;

  cursor: pointer;

  transition: opacity ${(p) => p.theme.transitions.duration.standard}ms
    ${(p) => p.theme.transitions.easing.easeOut};
`;
