import styled from "styled-components";

import Container from "@material-ui/core/Container";

import { header_height } from "../@global/const";

export const MainContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .pages-container {
    flex: 1;
    overflow: auto;
    height: calc(100vh - ${header_height}px);

    &.small {
      margin-left: ${(p) => p.collapsed_width}px;
    }
  }

  main {
    position: relative;
    padding-top: ${header_height}px;

    flex-grow: 1;

    display: flex;

    min-height: 100%;
  }
`;
