import gql from "graphql-tag";

/**
 * Queries
 */

export const USER = gql`
  query($username: String!) {
    userItems(filters: { username: $username }) {
      id
      username
      first_name
      last_name
      email
      avatar
      gender
      nationality
      country_of_residence
      timezone
      organization_id
      bio
      specialization
      publication
      career_stage
      orcid
      orcid_settings
      alt_emails
    }
  }
`;

export const COUNTRIES = gql`
  query {
    countryItems {
      id
      name
      code
    }
  }
`;

/**
 * Mutations
 */

export const UPDATE_USER = gql`
  mutation(
    $username: String!
    $email: String
    $first_name: String
    $last_name: String
  ) {
    updateUser(
      input: {
        username: $username
        email: $email
        first_name: $first_name
        last_name: $last_name
      }
    ) {
      id
      username
      first_name
      last_name
      email
      avatar
      gender
      nationality
      country_of_residence
      timezone
      organization_id
      bio
      specialization
      publication
      career_stage
      orcid
      orcid_settings
      alt_emails
    }
  }
`;

export const UPDATE_USER_DATA = gql`
  mutation(
    $username: String!
    $avatar: JSON
    $gender: String
    $nationality: String
    $country_of_residence: String
    $timezone: String
    $organization_id: ID
    $bio: String
    $specialization: JSON
    $publication: JSON
    $career_stage: String
    $orcid: String
    $orcid_settings: JSON
    $alt_emails: JSON
  ) {
    updateUserData(
      input: {
        username: $username
        avatar: $avatar
        gender: $gender
        nationality: $nationality
        country_of_residence: $country_of_residence
        timezone: $timezone
        organization_id: $organization_id
        bio: $bio
        specialization: $specialization
        publication: $publication
        career_stage: $career_stage
        orcid: $orcid
        orcid_settings: $orcid_settings
        alt_emails: $alt_emails
      }
    ) {
      id
      username
      first_name
      last_name
      email
      avatar
      gender
      nationality
      country_of_residence
      timezone
      organization_id
      bio
      specialization
      publication
      career_stage
      orcid
      orcid_settings
      alt_emails
    }
  }
`;

export const REMOVE_AVATAR = gql`
  mutation($username: String!) {
    removeAvatar(input: { username: $username }) {
      id
      username
      first_name
      last_name
      email
      avatar
      gender
      nationality
      country_of_residence
      timezone
      organization_id
      bio
      specialization
      publication
      career_stage
      orcid
      orcid_settings
      alt_emails
    }
  }
`;
